// Partners.js
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';// Make sure to create this CSS file
import clientLogo1 from "../assets/img/p1.png";
import clientLogo2 from "../assets/img/p2.png";
import clientLogo3 from "../assets/img/p3.png";
import clientLogo4 from "../assets/img/p4.png";
import clientLogo5 from "../assets/img/p5.png";
import clientLogo6 from "../assets/img/p6.png";
 
const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  
  export const Partners = () => {
    return (
      <div className="partners">
        <h2 className="heading">Partnered<span className="why-highlight"> With</span></h2>
        <Carousel 
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={2000}
          infinite={true}
          swipeable={true}
          draggable={false}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className="partner-logo">
            <img className="imagica" src={clientLogo1} alt="Client Logo 1" />
          </div>
          <div className="partner-logo">
            <img className="imagica" src={clientLogo2} alt="Client Logo 2" />
          </div>
          <div className="partner-logo">
            <img className="imagica" src={clientLogo3} alt="Client Logo 3" />
          </div>
          <div className="partner-logo">
            <img className="imagica" src={clientLogo4} alt="Client Logo 4" />
          </div>
          <div className="partner-logo">
            <img className="imagica" src={clientLogo5} alt="Client Logo 5" />
          </div>
          <div className="partner-logo">
            <img className="imagica" src={clientLogo6} alt="Client Logo 6" />
          </div>
        </Carousel>
      </div>
    );
  };
  
  export default Partners;
