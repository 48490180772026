import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import dm1 from "../assets/img/dm1.jpg";
import dm2 from "../assets/img/dm2.jpg";
import dm3 from "../assets/img/dm3.jpg";
import dm4 from "../assets/img/dm4.jpg";
import dm5 from "../assets/img/dm5.jpg";
import dm6 from "../assets/img/dm6.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/projImg3.jpg";
import projImg5 from "../assets/img/ml.png";
import projImg4 from "../assets/img/text.jpg";
import projImg6 from "../assets/img/data.png";
import projImg7 from "../assets/img/trans.png";
import web1 from "../assets/img/web1.webp";
import web2 from "../assets/img/web2.png";
import web3 from "../assets/img/web3.jpg";
import web4 from "../assets/img/web4.jpg";
import web5 from "../assets/img/web5.png";
import web6 from "../assets/img/web6.jpeg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
  const projects = [
    {
      title: "Digital Marketing 1",
      description: "Search Engine Optimization (SEO)",
      imgUrl: dm1,
      category: "first"
    },
    {
      title: "Digital Marketing 2",
      description: "Social Media Marketing",
      imgUrl: dm2,
      category: "first"
    },
    {
      title: "Digital Marketing 3",
      description: "Content Marketing",
      imgUrl: dm3,
      category: "first"
    },
    {
      title: "Digital Marketing 4",
      description: "Web Analytics",
      imgUrl: dm4,
      category: "first"
    },
    {
      title: "Digital Marketing 5",
      description: "Performance Marketing",
      imgUrl: dm5,
      category: "first"
    },
    {
      title: "Digital Marketing 6",
      description: "Conversion Rate Optimization (CRO)",
      imgUrl: dm6,
      category: "first"
    },
    {
      title: "Web Development 1",
      description: "Custom Website Design and Development",
      imgUrl: web1,
      category: "second"
    },
    {
      title: "Web Development 2",
      description: "E-commerce Development",
      imgUrl: web2,
      category: "second"
    },
    {
      title: "Web Development 3",
      description: "Content Management Systems (CMS)",
      imgUrl: web3,
      category: "second"
    },
    {
      title: "Web Development 4",
      description: "Web Application Development",
      imgUrl: web4,
      category: "second"
    },
    {
      title: "Web Development 5",
      description: "Mobile App Development",
      imgUrl: web5,
      category: "second"
    },
    {
      title: "Web Development 6",
      description: "API Development and Integration",
      imgUrl: web6,
      category: "second"
    },
    {
      title: "AI Solutions 1",
      description: "Chatbots and Virtual Assistants",
      imgUrl: projImg2,
      category: "third"
    },
    {
      title: "AI Solutions 2",
      description: "Image Recognition and Computer Vision",
      imgUrl: projImg3,
      category: "third"
    },
    {
      title: "AI Solutions 3",
      description: "Text Summarization",
      imgUrl: projImg4,
      category: "third"
    },
    {
      title: "AI Solutions 4",
      description: "Machine Learning Development(ML)",
      imgUrl: projImg5,
      category: "third"
    },
    {
      title: "AI Solutions 5",
      description: "Data Generation",
      imgUrl: projImg6,
      category: "third"
    },
    {
      title: "AI Solutions 6",
      description: "Data Translation",
      imgUrl: projImg7,
      category: "third"
    },
  ];

  return (
    <section className="project" id="services">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                  <h2><span className="why-highlight">What</span> We Do</h2>
                  <p>We specialize in web development, AI solutions, and digital marketing services, delivering cutting-edge innovations and strategic expertise to enhance your business landscape.</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Digital Marketing</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Web Development</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Artificial Intelligence</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.filter(project => project.category === "first").map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {projects.filter(project => project.category === "second").map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {projects.filter(project => project.category === "third").map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
