// src/Loading.js
import React from 'react';
import logo from '../assets/img/NitageLogo.png';
import '../App.css';

const Loading = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <img src={logo} alt="Loading..." />
      </div>
    </div>
  );
};

export default Loading;
