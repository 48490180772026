import * as React from 'react';
import { styled } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FaqImage from "../assets/img/faq.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const OuterContainer = styled('div')(({ theme }) => ({
    padding: '2rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '1rem 0',
    },
}));

const InnerContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    background: '#FFFFFF',
    borderRadius: '55px',
    color: '#121212',
    padding: '85px 50px 40px 40px',
    marginBottom: '20px',
    marginTop: '-122px',
    maxWidth: '80rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: '2rem',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: '50px 20px',
        gap: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '30px 10px',
    },
}));

export default function FAQ() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


  const faqs = [
    {
      question: 'What happens if we’re not satisfied with the results?',
      answer: 'At NitageDL, client satisfaction is paramount. If you’re not satisfied with the results ,we encourage open communication to discuss the issues. We offer revisions and strategic pivots to better align with your goals and ensure a successful outcome.',
    },
    {
        question: 'How does NitageDL stay ahead of the rapidly changing digital landscape?',
        answer: 'We continually invest in training and development for our team, adopt the latest technologies, and apply industry best practices to ensure our solutions are innovative and effective. Our proactive approach helps us adapt to changes and trends in the digital world.',
      },
      {
        question: 'Is my data secure with NitageDL?',
        answer: 'Yes, data security is a top priority for us. We implement robust security protocols, comply with industry standards, and conduct regular audits to ensure your data is protected at all times.',
      },
      {
        question: 'How do you measure the success of a project?',
        answer: 'Success is measured using clear, agreed-upon metrics that may include website traffic, conversion rates, engagement metrics, and overall ROI. We provide detailed reports and analytics to demonstrate progress and results throughout the project lifecycle.',
      },
      {
        question: 'What is the process for starting a project with NitageDL?',
        answer: 'The process begins with a consultation to understand your goals and challenges, followed by a strategic proposal tailored to your business needs. Once approved, we move forward with the implementation of the project, keeping you informed at every step.',
      },
      {
        question: 'How does NitageDL ensure client satisfaction?',
        answer: 'We prioritize regular communication and updates, provide transparent reports on progress, and adapt strategies as needed to ensure we meet and exceed client expectations.',
      },
  ];

  return (
    <OuterContainer>
        <InnerContainer id='faq'>
            <img src={FaqImage} alt="FAQ" style={{ width: '100%', maxWidth: '500px' }} />
            <div style={{ width: '100%' }}>
                <Typography 
                    variant="h2" 
                    sx={{
                        mb: 4,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    FA<span style={{ color: '#AA367C' }}>Q</span>
                </Typography>
                {faqs.map((faq, index) => (
                    <Accordion 
                        key={index}
                        expanded={expanded === `panel${index}`} 
                        onChange={handleChange(`panel${index}`)}
                        sx={{ background: '#fafafa', mb: 1, boxShadow: 'none', border: 'none' }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                        >
                            <Typography sx={{ fontWeight: 'bold' }}>
                            <FontAwesomeIcon icon={faArrowRight} className="fa-tilted-arrow" />
                                {faq.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{faq.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </InnerContainer>
    </OuterContainer>
);
}