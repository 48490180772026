import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Container, Row, Col } from 'react-bootstrap';
import TrackVisibility from 'react-on-screen';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { gapi } from 'gapi-script';
import emailjs from 'emailjs-com';

const API_KEY = 'AIzaSyAjYNdNoVF4WM0HztQJ5cupMaIOHFOKwDs';
const CALENDAR_ID = 'nupur.g@nitagedl.com';

export const BookSlot = () => {
  const [formDetails, setFormDetails] = useState({ name: '', email: '', reason: '' });
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [buttonText, setButtonText] = useState('Book A Slot');
  const [status, setStatus] = useState({});

  useEffect(() => {
    const loadCalendar = () => {
      gapi.client.load('calendar', 'v3', () => {
        gapi.client.calendar.events.list({
          calendarId: CALENDAR_ID,
          timeMin: (new Date()).toISOString(),
          showDeleted: false,
          singleEvents: true,
          maxResults: 50,
          orderBy: 'startTime',
          key: API_KEY,
        }).then(response => {
          const events = response.result.items;
          setAvailableSlots(events.map(event => ({
            start: new Date(event.start.dateTime),
            end: new Date(event.end.dateTime),
            title: event.summary
          })));
        }).catch(error => {
          console.error('Error loading calendar events:', error);
          toast.error('Failed to load calendar events. Please try again later.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      });
    };

    gapi.load('client', () => {
      gapi.client.setApiKey(API_KEY);
      loadCalendar();
    });
  }, []);

  const onFormUpdate = (category, value) => {
    setFormDetails(prevState => ({
      ...prevState,
      [category]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonText("Sending...");

    const bookingDetails = {
      name: formDetails.name,
      email: formDetails.email,
      reason: formDetails.reason,
      slotDateTime: selectedDate ? selectedDate.toString() : 'No date selected'
    };

    emailjs.send('service_yopaj39', 'template_cwwc7pa', bookingDetails, 'eQEBos7-VuLL1jBec')
      .then(response => {
        console.log('SUCCESS!', response.status, response.text);
        setButtonText("Book A Slot");
        setFormDetails({ name: '', email: '', reason: '' });
        setSelectedDate(null);
        setStatus({ success: true });
        toast.success('Slot booked successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000); // Refresh the page after 3 seconds
      }, err => {
        console.log('FAILED...', err);
        setButtonText("Book A Slot");
        setStatus({ success: false, message: 'Something went wrong, please try again later.' });
        toast.error('Something went wrong, please try again later.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const isTimeSlotAvailable = (time) => {
    const selectedTime = time.getTime();
    return !availableSlots.some(slot => selectedTime >= slot.start.getTime() && selectedTime < slot.end.getTime());
  };

  const isFormValid = () => {
    return formDetails.name && formDetails.email && formDetails.reason && selectedDate;
  };

  return (
    <section className="book-slot" id="book-slot">
      <ToastContainer />
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col xs={12} md={8}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2 >Book A Slot with NitageDL</h2>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12} sm={6} className="px-1">
                        <input type="text" value={formDetails.name} placeholder="Name" onChange={(e) => onFormUpdate('name', e.target.value)} required />
                      </Col>
                      <Col xs={12} sm={6} className="px-1">
                        <input type="email" value={formDetails.email} placeholder="Email" onChange={(e) => onFormUpdate('email', e.target.value)} required />
                      </Col>
                      <Col xs={12} className="px-1">
                        <DatePicker
                          selected={selectedDate}
                          onChange={date => setSelectedDate(date)}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy h:mm aa"
                          placeholderText="Select a date and time"
                          filterTime={isTimeSlotAvailable}
                          required
                          className="custom-datepicker"
                        />
                      </Col>
                      <Col xs={12} className="px-1">
                        <textarea rows="6" value={formDetails.reason} placeholder="Reason for Booking" onChange={(e) => onFormUpdate('reason', e.target.value)} required></textarea>
                      </Col>
                      
                      <Col xs={12}>
        <button type="submit" disabled={!isFormValid()}><span>{buttonText}</span></button>
      </Col>
                      {status.message && (
                        <Col>
                          <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                        </Col>
                      )}
                    </Row>
                  </form>
                </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
