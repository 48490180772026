import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import logo1 from '../assets/img/MainLogo.png';
import logo2 from '../assets/img/logoo.png';
import logo3 from '../assets/img/client-logo2.png';
import logo4 from '../assets/img/auto1.png';



const testimonials = [
    {
        id: 1,
        logo: logo1,
        name: "LAWFAX",
        stars: 5,
        description: "The team at NitageDL provided us with insights that were crucial for outperforming our competition. Their detailed competitor analysis helped us identify key opportunities, which they then helped us capitalize on. We couldn't be happier with the results!"
    },
    {
        id: 2,
        logo: logo2,
        name: "Engineer Constructions",
        stars: 5,
        description: "If you're looking for a partner to help navigate the complexities of digital marketing, look no further than NitageDL. Their hands-on approach and customized plans have made a significant difference in how we approach our business strategy."
    },
    {
        id: 3,
        logo: logo3,
        name: "HG Eaton",
        stars: 5,
        description: "NitageDL initial consultation offered deep insights into our digital footprint and what we were missing. Their approach is not only systematic but also incredibly effective. We've seen a dramatic improvement in our online engagement thanks to their expertise"
    },
    {
        id: 4,
        logo: logo4,
        name: "Auto Dealz",
        stars: 5,
        description: "Since partnering with NitageDL digital marketing firm, our website, AutoDealz.in, has experienced a remarkable 50% growth in sales and a 40% increase in revenue. Their expertise in digital marketing strategies and their commitment to our success have truly made a significant impact."
    }
];

const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1024 }, items: 1 },
    desktop: { breakpoint: { max: 1024, min: 768 }, items: 1 },
    tablet: { breakpoint: { max: 768, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
};

export const Testimonials = () => {
    return (
        <div className="testimonial-carousel-container">
            <Carousel 
                responsive={responsive}
                swipeable={true}
                draggable={false}
                showDots={true}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition="all .5s"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
            >
                {testimonials.map(testimonial => (
                    <div key={testimonial.id} className="testimonial-item">
                       
                        <img src={testimonial.logo} alt={testimonial.name} className="testimonial-logo" />
                       
                        <h3 className="testimonial-name">{testimonial.name}</h3>
                        <div className="testimonial-stars">
                            {'★'.repeat(testimonial.stars)}
                        </div>
                        <p className="testimonial-description">{testimonial.description}</p>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default Testimonials