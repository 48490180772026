import { Container, Row, Col, Nav  } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from '../assets/img/NitageDL-fulllogo.png';
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import whatsappIcon from "../assets/img/whatsapp1.png"; 

export const Footer = () => {
  const phoneNumber = "9056869267";
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6} className="samerow">
            <img className="footerimg" src={logo} alt="Logo" />
            <Nav className="footer-nav">
              <Nav.Link href="#about">About us</Nav.Link>
              <Nav.Link href="#services">Services</Nav.Link>
              <Nav.Link href="#whyus">Why us</Nav.Link>
              <Nav.Link href="#faq">FAQ's</Nav.Link>
              <Nav.Link href="#connect">Contact Us</Nav.Link>
              <Nav.Link href="#book-slot">Book A Slot</Nav.Link>
            </Nav>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/company/nitage-design-labs-private-limited/?viewAsMember=true"><img className="footerimg1" src={navIcon1} alt="Icon" /></a>
              {/* <a href="#"><img className="footerimg1" src={navIcon2} alt="Icon" /></a>
              <a href="#"><img className="footerimg1" src={navIcon3} alt="Icon" /></a> */}
              <a href={`https://wa.me/${phoneNumber}`} target="_blank">
                <img className="footerimg2" src={whatsappIcon} alt="WhatsApp Icon" />
              </a>
            </div>
            <p className="footerp">© Oct 2022 NitageDL. All right reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
