import React, { useEffect, useRef, useState } from 'react';
import backgroundImage from "../assets/img/Design_vid5.mp4";
import smallScreenVideo from "../assets/img/design_vedio.mp4" ; 
import Spinner from 'react-bootstrap/Spinner';

export const Roadmap = () => {
  const [isLoading, setIsLoading] = useState(true);
  const videoRef = useRef(null);
  const videoRefSmall = useRef(null);

  useEffect(() => {
    // Simulate a delay or wait for data to load
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the delay as needed
  }, []);

  if (isLoading) {
    return <div className="loading">
    
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>    
    
    </div>;
  }


  return (
    <section id="pathway" className="roadmap-container">
      <div className="roadmap1">
        <video 
          ref={videoRef}
          src={backgroundImage}
          alt="Background Video"
          className="background-video"
          autoPlay
          loop
          muted
          playsInline
          width="100%"
          height="auto"
        />
        <video 
          ref={videoRefSmall}
          src={smallScreenVideo}
          alt="Background Video for Small Screens"
          className="background-video-small"
          autoPlay
          loop
          muted
          playsInline
          width="100%"
          height="auto"
        />
      </div>
    </section>
  );
}

export default Roadmap;
