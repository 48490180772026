import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/Panda1.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';


export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Building Tomorrow’s Solutions Today" ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }
  const navigateToConnect = () => {
    window.location.hash = "connect";
  };
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">Welcome to Nitage Design Labs</span>
                <h1> <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Building Tomorrow’s Solutions Today" ]'><span className="wrap">{text}</span></span></h1>
                <p className="description">
        NitageDL began with the vision of crafting cutting-edge digital experiences, 
        from advanced web and app development to strategic AI solutions. Our global 
        presence has expanded to not just metropolitan hotspots but also to the emerging 
        digital frontiers of small and rural towns. We have mastered the art of enhancing 
        visibility and user engagement for our partners. 
      </p>
      <p className="description">
        Our team is a collective of imaginative creators, insightful strategists, 
        and analytics aficionados. Together, we have forged a space where digital 
        marketing not only thrives but also drives growth. Let's embark on this 
        journey to redefine digital possibilities and share in the success that 
        tomorrow brings.
      </p>
      
                  <button onClick={navigateToConnect}>Let’s Connect <ArrowRightCircle size={25} /></button>
                  
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5} >
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
