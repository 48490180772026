import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import  Roadmap  from "./components/roadmap";
import { WhyUs } from "./components/Skills";
import { Partners } from "./components/Partners";
import { Projects } from "./components/Projects";
import FAQ  from "./components/FAQ";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { Testimonials } from "./components/Testimonials";
import { BookSlot } from "./components/BookSlot";


function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Roadmap />
      <Partners />
      <Projects />
      <WhyUs />
      <FAQ />
      <BookSlot />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
