
import React from 'react';
import Carousel from 'react-multi-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import 'react-multi-carousel/lib/styles.css';
import clientLogo1 from "../assets/img/client-logo1.png";
import clientLogo2 from "../assets/img/client-logo2.png";
import clientLogo3 from "../assets/img/client-logo3.png";
import clientLogo4 from "../assets/img/client-logo4.png";
import clientLogo5 from "../assets/img/client-logo5.png";
import clientLogo6 from "../assets/img/client-logo6.png";
import clientLogo7 from "../assets/img/client-logo7.png";
import clientLogo8 from "../assets/img/client-logo8.png";
import clientLogo9 from "../assets/img/client-logo9.png";
import clientLogo10 from "../assets/img/client-logo10.png";
import clientLogo11 from "../assets/img/auto.png";
import arrow1 from "../assets/img/arrow1.svg"; // If needed for custom arrows
import arrow2 from "../assets/img/arrow2.svg"; // If needed for custom arrows
import colorSharp from "../assets/img/color-sharp.png";
import colorSharp2 from "../assets/img/color-sharp2.png";

export const WhyUs = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="why-us" id="whyus">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="why-us-bx">
                    <h2><span className="why-highlight">Why</span> Us</h2>
                        <p>We draw learnings from our experiences of managing services for leading brands and help in identifying opportunities and gaps in strategy & execution.</p>
                        <Carousel 
  responsive={responsive} 
  infinite={true} 
  className="client-slider"
  autoPlay={true}
  autoPlaySpeed={2000} // Auto play speed set to 2000 milliseconds (2 seconds)
  swipeable={true}
  draggable={false}
  showDots={false}
  slidesToSlide={1} // Number of slides to scroll at once
>
                            <div className="item">
                                <img src={clientLogo1} alt="Client Logo" />
                            </div>
                            <div className="item">
                                <img src={clientLogo2} alt="Client Logo" />
                            </div>
                            <div className="item">
                                <img src={clientLogo3} alt="Client Logo" />
                            </div>
                            <div className="item">
                                <img src={clientLogo4} alt="Client Logo" />
                            </div>
                            <div className="item">
                                <img src={clientLogo5} alt="Client Logo" />
                            </div>
                            <div className="item">
                                <img src={clientLogo6} alt="Client Logo" />
                            </div>
                            <div className="item">
                                <img src={clientLogo7} alt="Client Logo" />
                            </div>
                            <div className="item">
                                <img src={clientLogo8} alt="Client Logo" />
                            </div>
                            <div className="item">
                                <img src={clientLogo9} alt="Client Logo" />
                            </div>
                            <div className="item">
                                <img src={clientLogo10} alt="Client Logo" />
                            </div>
                            <div className="item">
                                <img src={clientLogo11} alt="Client Logo" />
                            </div>
                        </Carousel>
                        <div className="value-propositions">
  <div className="row">
    <div className="col-md-6 proposition">
      <h3><FontAwesomeIcon icon={faArrowRight} className="fa-tilted-arrow" />Full-Service Expertise</h3>
      <p>Offering a comprehensive suite of digital marketing, web development, and cutting-edge AI solutions from a single source.</p>
    </div>
    <div className="col-md-6 proposition">
      <h3><FontAwesomeIcon icon={faArrowRight} className="fa-tilted-arrow" />Customized and Data-Driven</h3>
      <p>Tailoring strategies to each client’s needs with a strong focus on data analytics for improved decision-making and results.</p>
    </div>
  </div>
  <div className="row">
    <div className="col-md-6 proposition">
      <h3><FontAwesomeIcon icon={faArrowRight} className="fa-tilted-arrow" />Experienced Professionals</h3>
      <p>A team of experts dedicated to innovation and excellence in delivering measurable success across all projects</p>
    </div>
    <div className="col-md-6 proposition">
      <h3><FontAwesomeIcon icon={faArrowRight} className="fa-tilted-arrow" />Client-Centric Approach</h3>
      <p>Committed to transparency, collaboration, and adapting solutions to scale with your business growth.</p>
    </div>
  </div>
</div>

                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
        <img className="background-image-Right" src={colorSharp2} alt="Image" />
    </section>
  )
}

export default WhyUs;
