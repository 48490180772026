import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import robot from "../assets/img/robotsvg.png";

export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email
    })
  }

  const clearFields = () => {
    setEmail('');
  }

  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
      <div className="new-email-bx">
              <img className="background-image-Right" src={robot} alt="Image" />
               
              </div>
        <Row>
          <Col lg={12} md={6} xl={5}>
            <h3>Embark on your digital journey with NitageDL's expertise</h3>
            <p className="contact-info" >
              Address USA: A Digital Agency of Adametnext LLC Duluth, Georgia 30097
            </p>
            
            <p className="contact-info" >
              Address India: 2372, Second Floor,Sector-57, Gurugram ( Greater Delhi)
            </p>
<p className="contact-info" >
              Address South Africa: Unit 1,243 Utrecht Street,Vryheid, 3100
            </p>
            <button className="new-email-bx-button"><a href="https://wa.me/16175437627" target="_blank" className="whatsapp-link">
                +1 617 5437627
              </a>
              <br/>
              
              <a href="https://wa.me/919056869267" target="_blank" className="whatsapp-link">
                +91-9056869267
              </a>
              <br/>
              <a href="https://wa.me/27824963039" target="_blank" className="whatsapp-link">
  +27 82 496 3039
</a>

            </button> 
            
            
          </Col>        
        </Row>
        
      </div>
    </Col>
  );
};